import { create } from 'zustand'

import {
	getItem as getStorageItem,
	removeItem,
	setItem as setStorageItem
} from '../storage'

export enum TutorialStatus {
	SKIPPED = 'skipped',
	GOING = 'going',
	REVIEW = 'review',
	ENDED = 'ended'
}

export const STATUS_KEY = 'tutorialStatus'
interface TutorialState {
	stepIndex: number | null
	tutorialStatus: TutorialStatus | null
	justFinishedTutorial: boolean
	setNextStep: (increment?: number) => void
	setTutorialStatus: (status: TutorialStatus) => void
	setJustFinishedTutorial: (value: boolean) => void
	getTutorialStatus: () => Promise<void>
	getTutorialStepIndex: () => number | null
	reset: () => void
}

export const useTutorialStore = create<TutorialState>(set => ({
	stepIndex: null,
	tutorialStatus: null,
	justFinishedTutorial: false,
	setJustFinishedTutorial: (value: boolean) =>
		set({ justFinishedTutorial: value }),
	setNextStep: (increment = 1) => {
		set(state => {
			if (state.tutorialStatus !== TutorialStatus.GOING) {
				return state
			}
			const newStepIndex =
				(state.stepIndex ?? 0) >= 6 ? 0 : (state.stepIndex ?? 0) + increment
			return { stepIndex: newStepIndex }
		})
	},
	setTutorialStatus: async (status: TutorialStatus) => {
		setStorageItem(STATUS_KEY, status)
		set(state => ({
			tutorialStatus: status,
			stepIndex:
				status === TutorialStatus.GOING && state.stepIndex === null
					? 0
					: state.stepIndex
		}))
	},
	getTutorialStatus: async () => {
		set({
			tutorialStatus: (await getStorageItem(STATUS_KEY)) as TutorialStatus
		})
	},
	getTutorialStepIndex: (): number | null => {
		return useTutorialStore.getState().stepIndex || null
	},
	reset: async () => {
		removeItem(STATUS_KEY)
		set({ tutorialStatus: null, stepIndex: null })
	}
}))
