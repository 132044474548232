import { useEffect, useRef } from 'react'

import {
	Box,
	Button,
	ButtonProps,
	Icon,
	List,
	ListItem,
	Modal,
	ModalContent,
	ModalOverlay,
	Text,
	UseControllableStateProps,
	forwardRef,
	omitThemingProps,
	useControllableState,
	useDisclosure,
	useMultiStyleConfig
} from '@chakra-ui/react'
import { useSize } from '@chakra-ui/react-use-size'
import { useDesktopBreakpoint } from '@wwt/custom/chakra-ui'
import { UseSelectProps, useSelect } from 'downshift'
import { Variants, motion } from 'framer-motion'

import ArrowDownIcon from '@wwt/shared/assets/icons/outline/arrow-down.svg'

import { SelectModal } from './SelectModal'

export interface SelectProps<T>
	extends Omit<ButtonProps, 'value' | 'defaultValue' | 'onChange'>,
		Pick<
			UseSelectProps<T>,
			'items' | 'itemToString' | 'itemToKey' | 'isItemDisabled'
		>,
		UseControllableStateProps<T | null> {
	icon?: React.ReactElement
	prefix?: string
	placeholder?: string
	render?: (item: T, isSelected: boolean) => React.ReactNode
}

const motionVariants: Variants = {
	enter: {
		visibility: 'visible',
		opacity: 1,
		scale: 1,
		transition: {
			duration: 0.2,
			ease: [0.4, 0, 0.2, 1]
		}
	},
	exit: {
		transitionEnd: {
			visibility: 'hidden'
		},
		opacity: 0,
		scale: 0.8,
		transition: {
			duration: 0.1,
			easings: 'easeOut'
		}
	}
}

// TODO: decompose this select to custom dropdown component
// so here will be only select logic without markup
const SelectInner = <T,>(
	{
		icon,
		prefix,
		placeholder,
		items,
		itemToString,
		itemToKey,
		render,
		value,
		defaultValue,
		onChange,
		shouldUpdate,
		isDisabled,
		isLoading,
		isItemDisabled = () => false,
		...props
	}: SelectProps<T>,
	ref: React.ForwardedRef<HTMLButtonElement>
) => {
	const [selectedItem, setSelectedItem] = useControllableState({
		defaultValue: defaultValue ?? null,
		value,
		onChange,
		shouldUpdate
	})

	const divProps = omitThemingProps(props)

	const buttonRef = useRef<HTMLButtonElement>(null)
	const buttonSize = useSize(buttonRef)

	const styles = useMultiStyleConfig('WwtSelect', props)

	const isDesktop = useDesktopBreakpoint()

	const {
		isOpen: isModalOpen,
		onClose: onModalClose,
		onOpen: onModalOpen
	} = useDisclosure()

	useEffect(() => {
		if (isDesktop) {
			onModalClose()
		}
	}, [isDesktop, onModalClose])

	const stateReducer: UseSelectProps<T>['stateReducer'] = (
		_state,
		actionAndChanges
	) => {
		const { type, changes } = actionAndChanges
		// this prevents the menu from being closed when the user opens a modal with 'Enter' or mouse
		switch (type) {
			case useSelect.stateChangeTypes.ToggleButtonClick:
				// open modal
				if (!isDesktop) {
					onModalOpen()
				}

				return changes // business as usual.
			default:
				return changes // otherwise business as usual.
		}
	}

	const {
		isOpen,
		highlightedIndex,
		selectItem,
		getToggleButtonProps,
		getMenuProps,
		getItemProps
	} = useSelect({
		items,
		itemToString,
		itemToKey,
		selectedItem,
		onSelectedItemChange: ({ selectedItem: newSelectedItem }) =>
			setSelectedItem(newSelectedItem),
		stateReducer,
		isItemDisabled
	})

	return (
		<Box
			ref={ref}
			{...divProps}
		>
			<Button
				data-wwt-id="select__main--button"
				isActive={isOpen}
				iconSpacing="auto"
				leftIcon={icon}
				rightIcon={
					<Icon
						as={ArrowDownIcon}
						// build doesn't pass without it
						// eslint-disable-next-line dot-notation
						__css={styles['icon']}
						transform={isOpen ? 'rotate(180deg)' : ''}
					/>
				}
				// build doesn't pass without it
				// eslint-disable-next-line dot-notation
				__css={styles['field']}
				isDisabled={isDisabled}
				isLoading={isLoading}
				{...getToggleButtonProps({
					ref: buttonRef,
					disabled: isDisabled || isLoading
				})}
			>
				<Text
					as="span"
					noOfLines={1}
				>
					{prefix} {selectedItem ? itemToString?.(selectedItem) : placeholder}
				</Text>
			</Button>

			{!isDesktop ? (
				<Modal
					isOpen={isModalOpen}
					onClose={onModalClose}
				>
					<ModalOverlay />
					<ModalContent {...getMenuProps({}, { suppressRefError: true })}>
						<SelectModal
							onClose={onModalClose}
							items={items}
							itemToKey={itemToKey}
							itemToString={itemToString}
							render={render}
							placeholder={placeholder}
							prefix={prefix}
							selectedItem={selectedItem}
							onSelect={selectItem}
							getItemProps={getItemProps}
							getMenuProps={getMenuProps}
						/>
					</ModalContent>
				</Modal>
			) : (
				<List
					as={motion.ul}
					variants={motionVariants}
					initial={false}
					animate={isOpen ? 'enter' : 'exit'}
					// build doesn't pass without it
					// eslint-disable-next-line dot-notation
					__css={styles['list']}
					w={buttonSize ? `calc(${buttonSize.width}px - 2rem)` : undefined}
					{...getMenuProps({}, { suppressRefError: true })}
				>
					{items.map((item, index) => {
						const props = getItemProps({ item, index })

						return (
							<ListItem
								data-wwt-id="select__item--button"
								sx={{
									// build doesn't pass without it
									// eslint-disable-next-line dot-notation
									...styles['item'],
									bg: highlightedIndex === index ? 'gray.100' : undefined
								}}
								key={itemToKey?.(item) ?? itemToString?.(item) ?? index}
								{...props}
							>
								{itemToString?.(item)}
								{render?.(item, props['aria-selected'])}
							</ListItem>
						)
					})}
				</List>
			)}
		</Box>
	)
}

export const Select = forwardRef(SelectInner) as <T>(
	// eslint-disable-next-line no-use-before-define
	props: SelectProps<T> & { ref?: React.ForwardedRef<HTMLDivElement> }
) => ReturnType<typeof SelectInner>
