import { ZodIssueCode } from 'zod'
import { RefinementCtx } from 'zod/lib/types'

import { bookablePackagedPackagesValidator } from './validators/bookablePackagedValidators/bookablePackagedPackagesValidator.ts'
import { featureHasOnlyOneSelectedOption } from './validators/featureHasOnlyOneSelectedOption/featureHasOnlyOneSelectedOption.ts'
import { allFeaturesNamesValidator } from './validators/featuresNameValidator/allFeaturesNamesValidator.ts'
import { featureOptionNamesValidator } from './validators/featuresNameValidator/featureOptionNamesValidator.ts'
import { responseContentImagesValidator } from './validators/responseContentImagesValidator/responseContentImagesValidator.ts'

export const AbstractFilterDtoValidation = () => {
	return false
}

export const ActiveFilterDtoValidation = () => {
	return false
}

export const AuthenticationRequestDtoValidation = () => {
	return false
}

export const BillingAddressValidation = () => {
	return false
}

export const BlogDtoValidation = () => {
	return false
}

export const BookableNotPackagedFeatureDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const options = data.options

	if (!featureHasOnlyOneSelectedOption(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have only one selected option`
		})
	}

	if (!featureOptionNamesValidator(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have unique option names`
		})
	}
}

export const BookableNotPackagedFeatureOptionDtoValidation = () => {
	return false
}

export const BookablePackageDtoValidation = () => {
	return false
}

export const BookablePackagedFeatureDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const options = data.options

	if (!featureHasOnlyOneSelectedOption(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have only one selected option`
		})
	}

	if (!featureOptionNamesValidator(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have unique option names`
		})
	}
}

export const BookablePackagedFeatureOptionDtoValidation = () => {
	return false
}

export const CancelReservationDtoValidation = () => {
	return false
}

export const CheckInFeatureDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const options = data.options

	if (!featureHasOnlyOneSelectedOption(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have only one selected option`
		})
	}

	if (!featureOptionNamesValidator(options)) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: `Feature ${data.name} must have unique option names`
		})
	}
}

export const CheckInFeatureOptionDtoValidation = () => {
	return false
}

export const ContactDataValidation = () => {
	return false
}

export const CreateReactionReasonDtoValidation = () => {
	return false
}

export const CreateReservationDtoValidation = () => {
	return false
}

export const DateMatrixDtoValidation = () => {
	return false
}

export const DeviceValidation = () => {
	return false
}

export const DislikeReactionDtoValidation = () => {
	return false
}

export const FeatureGroupValidation = () => {
	return false
}

export const FilterDtoValidation = () => {
	return false
}

export const FilterResponseDtoValidation = () => {
	return false
}

export const FlagSearchDtoValidation = () => {
	return false
}

export const GeolocationDtoValidation = () => {
	return false
}

export const GeolocationValidation = () => {
	return false
}

export const GuestDetailsDtoValidation = () => {
	return false
}

export const GuestDtoValidation = () => {
	return false
}

export const GuestQuantityDtoValidation = () => {
	return false
}

export const HotelDynamicDataDtoValidation = () => {
	return false
}

export const HotelSearchDtoValidation = () => {
	return false
}

export const HotelStaticDataDtoValidation = () => {
	return false
}

export const ImageDtoValidation = () => {
	return false
}

export const ImagePropertiesDtoValidation = () => {
	return false
}

export const IncludedFeatureDtoValidation = () => {
	return false
}

export const IncludedFeatureOptionDtoValidation = () => {
	return false
}

export const InfoProvidedValidation = () => {
	return false
}

export const IntegrationSearchDtoValidation = () => {
	return false
}

export const JwtResponseDtoValidation = () => {
	return false
}

export const LikeReactionDtoValidation = () => {
	return false
}

export const MetadataValidation = () => {
	return false
}

export const OfferCreateResponseDtoValidation = () => {
	return false
}

export const OfferDtoValidation = () => {
	return false
}

export const OfferDynamicDataDtoValidation = () => {
	return false
}

export const OfferMetaDataDtoValidation = () => {
	return false
}

export const OfferReactionBaseMetaDataDtoResponseListValidation = () => {
	return false
}

export const OfferReactionBaseMetaDataDtoValidation = () => {
	return false
}

export const OfferStaticDataDtoValidation = () => {
	return false
}

export const OffersResponseDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const offersWithErrors = [
		...responseContentImagesValidator(data),
		...allFeaturesNamesValidator(data)
	]

	offersWithErrors.forEach(offerWithError => {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			...offerWithError
		})
	})
}

export const OptionFilterDtoValidation = () => {
	return false
}

export const PageResponseWrapperValidation = () => {
	return false
}

export const PaginationDtoValidation = () => {
	return false
}

export const PaymentCardValidation = () => {
	return false
}

export const PaymentDataValidation = () => {
	return false
}

export const PointDtoValidation = () => {
	return false
}

export const RangeParamsDtoValidation = () => {
	return false
}

export const ReactionReasonDtoValidation = () => {
	return false
}

export const RequestStatusDtoValidation = () => {
	return false
}

export const ResponseErrorDtoValidation = () => {
	return false
}

export const RoomDynamicDataDtoValidation = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	ctx: RefinementCtx
) => {
	const errorsAfterValidation = bookablePackagedPackagesValidator(
		data.bookablePackagedFeatures
	)

	errorsAfterValidation.forEach(error => {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			path: error.path ?? [],
			...error
		})
	})

	const checkInDate = new Date(data.checkIn)
	const checkOutDate = new Date(data.checkOut)

	if (checkOutDate.getTime() <= checkInDate.getTime()) {
		ctx.addIssue({
			code: ZodIssueCode.custom,
			message: 'checkOut date must be later than checkIn date.'
		})
	}
}

export const RoomFaqDtoValidation = () => {
	return false
}

export const RoomReviewAndFaqDtoValidation = () => {
	return false
}

export const RoomReviewDtoValidation = () => {
	return false
}

export const RoomSearchDtoValidation = () => {
	return false
}

export const RoomStaticDataDtoValidation = () => {
	return false
}

export const SearchOffersDtoValidation = () => {
	return false
}

export const SortDtoValidation = () => {
	return false
}

export const SortResponseDtoValidation = () => {
	return false
}

export const StayDtoValidation = () => {
	return false
}

export const StayPeriodDtoValidation = () => {
	return false
}

export const ThreeDsDataValidation = () => {
	return false
}

export const TypeableFilterDtoValidation = () => {
	return false
}
