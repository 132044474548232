import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

import { menuTheme } from './menuTheme'

const { defineMultiStyleConfig, definePartsStyle } =
	createMultiStyleConfigHelpers([
		'popper',
		'list',
		'item',
		'icon',
		'groupTitle'
	])

const baseStyleList = defineStyle({
	...menuTheme.baseStyle?.list,
	py: '0',
	minW: '0',
	mx: '1rem',
	borderTopRadius: '0',
	borderTopWidth: '0'
})

const baseStyleItem = defineStyle({
	...menuTheme.baseStyle?.item,
	display: 'flex',
	alignItems: 'center',
	w: 'full',
	textAlign: 'left',
	cursor: 'pointer'
})

const baseStyleGroupTitle = defineStyle({
	...menuTheme.baseStyle?.groupTitle
})

const baseStyleIcon = defineStyle({
	...menuTheme.baseStyle?.icon
})

const baseStyle = definePartsStyle({
	list: baseStyleList,
	item: baseStyleItem,
	groupTitle: baseStyleGroupTitle,
	icon: baseStyleIcon
})

export const dropdownTheme = defineMultiStyleConfig({
	baseStyle
})
