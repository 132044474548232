import { omit } from 'radash'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface State {
	/**
	 * Map of <id, serverUrl>
	 */
	serverUrls: Record<number, string>
	/**
	 * Id of selected server url
	 */
	selectedServerUrlId: number
}

interface Actions {
	/**
	 * Add new server url
	 * @param id {number} - Id of new server url
	 * @param url - Server url
	 */
	addServerUrl: (id: number, url: string) => void
	/**
	 * Create new empty server url
	 */
	addEmptyServerUrl: () => void
	/**
	 * Remove server url by id
	 * @param id {number} - Id of server url for remove
	 *
	 * **If it last server url - don't remove it**
	 */
	removeServerUrl: (id: number) => void
	/**
	 * Update server url by id
	 * @param id {number} - Id of server url for update
	 * @param newUrl - New url string
	 */
	updateServerUrl: (id: number, newUrl: string) => void
	/**
	 * Get current server url for requests
	 */
	getCurrentServerUrl: () => string
	/**
	 * Set selected server url id
	 * @param id {number} - Id of server url for select
	 */
	setSelectedServerUrlId: (id: number) => void
}

/**
 * Store for save server urls and selected server url id
 *
 * @author Ivan Shchedrovskyi
 */
export const useAdminServerUrl = create<State & Actions>()(
	persist(
		(set, get) => ({
			serverUrls: {
				0: 'https://dev.winwin.travel',
				1: 'http://localhost:3001',
				2: 'http://localhost:8080',
				3: 'https://mock.apidog.com/m1/688237-661332-default'
			},
			selectedServerUrlId: 3,

			addServerUrl: (id: number, url: string) =>
				set(state => {
					return {
						serverUrls: {
							...state.serverUrls,
							[id]: url
						}
					}
				}),
			addEmptyServerUrl: () =>
				set(state => {
					const lastId = Math.max(...Object.keys(state.serverUrls).map(Number))

					return {
						serverUrls: {
							...state.serverUrls,
							[lastId + 1]: import.meta.env.VITE_API_URL_REMOTE
						}
					}
				}),
			removeServerUrl: (id: number) =>
				set(state => {
					if (Object.keys(state.serverUrls).length === 1) {
						return {}
					}

					const serverUrls = omit(state.serverUrls, [id])

					const firstId = Math.min(...Object.keys(serverUrls).map(Number))

					return {
						serverUrls,
						selectedServerUrlId:
							id === state.selectedServerUrlId
								? firstId
								: state.selectedServerUrlId
					}
				}),
			updateServerUrl: (id: number, newUrl: string) =>
				set(state => {
					const serverUrls = structuredClone(state.serverUrls)
					serverUrls[id] = newUrl

					return {
						serverUrls
					}
				}),
			getCurrentServerUrl: () => {
				return get().serverUrls[get().selectedServerUrlId]
			},
			setSelectedServerUrlId: (id: number) =>
				set(state => {
					if (!(id in state.serverUrls)) {
						return {}
					}

					return {
						selectedServerUrlId: id
					}
				})
		}),
		{
			name: 'admin-server-url-v1-01' // name of the item in the storage (must be unique)
			// by default, 'localStorage' is used
		}
	)
)
